<template>
  <v-container class="pa-0">
    <SortableToggleListShow
      v-if="mode == 0"
      v-bind:subtitle="subtitle"
      v-bind:datas="tmpDatas"
      :handle-toggle-mode="toggleMode"
      :handle-sort-choices="sortChoices"
    />
    <SortableToggleListEdit
      v-if="mode == 1"
      v-bind:subtitle="subtitle"
      v-bind:datas="tmpDatas"
      v-bind:menus="menus"
      :handle-toggle-mode="toggleMode"
      :handle-update-choices="updateChoices"
    />
  </v-container>
</template>

<script>
const _cloneDeep = require("lodash.clonedeep");
export default {
  name: "SortableToggleList",
  components: {
    SortableToggleListShow: () => import("./SortableToggleListShow"),
    SortableToggleListEdit: () => import("./SortableToggleListEdit"),
  },
  props: {
    subtitle: {
      type: String,
      required: false,
      default: "",
    },
    datas: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    menus: {
      type: Array, //参照渡し(Object/Array)
      required: true,
      default: () => [],
    },
    handleToggleMode: {
      type: Function,
      required: true,
    },
    handleUpdateChoices: {
      type: Function,
      required: true,
    },
    handleSortChoices: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    mode: 0, //モード切替(0:表示 1:編集)
    tmpDatas: [],
  }),
  created() {},
  watch: {
    datas: {
      handler: function (newValue) {
        // 作業用データ
        this.tmpDatas = _cloneDeep(newValue);
      },
      deep: true,
      immediate: true, // 初回読込時も動作させる
    },
  },
  methods: {
    toggleMode() {
      const oldMode = this.mode;
      const newMode = oldMode === 0 ? 1 : 0; // モード(0:表示 1:編集)
      this.mode = newMode;
      this.handleToggleMode(newMode == 1);
    },
    updateChoices(newChoices) {
      this.handleUpdateChoices(newChoices);
    },
    sortChoices(newChoices) {
      this.handleSortChoices(newChoices);
    },
  },
};
</script>
